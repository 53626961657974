<template>
  <div class="w-full border border-gray-300 rounded-10 overflow-hidden flex flex-col">
    <MessengerHistory class="flex-grow" />
  </div>
</template>

<script>
import MessengerHistory from '@/components/Messenger/MessengerHistory.vue';

export default {
  name: 'Messenger',
  components: {
    MessengerHistory,
  },
};
</script>
